.loginLogo {
  display: block;
  margin: 15px auto 50px;
  max-width: 135px;
}

.card {
  width: 100%;
  max-width: 465px;
  margin: 0 auto 10px;
}

.label {
  color: var(--color-dark-blue1);
  font-size: 0.875rem;
  font-weight: 500;
}

.forgotPassword {
  color: #000;
  font-size: 0.6875rem;
  padding: 0;
}

.smallText {
  font-size: 0.75rem;
}

.warningIcon {
  color: red;
  margin-right: 5px;
}

.emailText {
  display: block;
  max-width: 320px;
  font-size: 1rem;
  margin: 0 auto 30px;
  text-align: center;
}
.emailResend {
  display: block;
  border: 0 none;
  color: #323232;
  font-size: 0.875rem;
  margin: 0 auto;
  padding: 0;
}
